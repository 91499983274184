import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { H3Icon, H3Button, H3Input } from '@h3/thinking-ui';
import CommonInput from './common-input.vue';
import { ModifyPasswordService, LoginService } from '@/sevices';
import { RsaEncrypt } from '@/common/rsg';
let ChangePassword = class ChangePassword extends Vue {
    constructor() {
        super(...arguments);
        this.mode = 'verification';
        // 密码
        this.password = '';
        this.newPassword = '';
        this.repeatNewPassword = '';
        // 确认密码的提示语
        this.reconfirmTip = '';
        // 新密码提示语
        this.newPasswordTip = '';
        // 是否有错误验证
        this.hasError = false;
        // 加密公钥
        this.rsaKey = {};
        this.passRuleData = {};
        this.typeOptions = [{
                label: '数字',
                value: 'digit',
            }, {
                label: '小写字母',
                value: 'lowercaseLetter',
            }, {
                label: '大写字母',
                value: 'uppercaseLetter',
            }, {
                label: '英文特殊字符（除空格）',
                value: 'specialCharacter',
            }];
    }
    // 是否可以下一步
    get verificationPassword() {
        return !this.password;
    }
    // 是否可以提交
    get submitPassword() {
        return !(this.password && this.newPassword && this.repeatNewPassword);
    }
    get baseUserId() {
        let publicUserInfo = localStorage.getItem('publicUserInfo') || '';
        try {
            publicUserInfo = JSON.parse(publicUserInfo);
            return publicUserInfo?.baseUserId || '';
        }
        catch (error) {
            return '';
        }
    }
    /**
     * 获取加密公钥
     */
    async getKey() {
        if (this.rsaKey.index)
            return true;
        // console.log(this.rsaKey.index);
        const res = await LoginService.getKey();
        const { success, data, errMessage = '服务异常！' } = res;
        if (!success || !data.index || !data.key) {
            this.$message.warning(errMessage);
            this.rsaKey = {};
            return false;
        }
        this.rsaKey = data;
        return true;
    }
    /**
     * 验证原密码
     */
    async requestService() {
        this.$toast.show({
            icon: 'loading',
            text: '加载中',
            duration: 300,
        });
        await this.getKey();
        const oldPassword = RsaEncrypt(this.password, this.rsaKey.key);
        const params = {
            oldPassword,
            index: this.rsaKey.index,
        };
        const { success, data } = await ModifyPasswordService.checkOldPassword(params, this.baseUserId);
        if (success && data) {
            this.mode = 'check';
        }
        else {
            this.$toast.show('密码不正确！');
            return;
        }
        const res = await ModifyPasswordService.getPasswordRuleInfo();
        if (res.success && res.data.passwordRuleStatus) {
            this.passRuleData = res.data;
        }
    }
    /**
     * 设置新密码
     */
    async requestServiceSetNew() {
        this.reconfirmBlur();
        this.newPasswordBlur();
        if (this.hasError)
            return;
        const oldPassword = RsaEncrypt(this.password, this.rsaKey.key);
        const newPassword = RsaEncrypt(this.newPassword, this.rsaKey.key);
        const params = {
            oldPassword,
            newPassword,
            index: this.rsaKey.index,
        };
        const { success } = await ModifyPasswordService.modifyPassword(params, this.baseUserId);
        if (success) {
            this.$message.success('设置成功！');
            localStorage.removeItem('sessToken');
            setTimeout(() => {
                this.updateHandler();
            }, 1000);
        }
        else {
            this.$message.warning('设置新密码失败！');
        }
    }
    updateHandler() {
        localStorage.clear();
        this.$router.push({
            name: 'login',
            params: { type: 'user' },
        });
    }
    evil(fn) {
        // 本质是eval()，防止eslint报错
        const Fn = Function; // 一个变量指向Function，防止有些前端编译工具报错
        return new Fn('return ' + fn)();
    }
    /**
     * 新密码失教校验
     */
    newPasswordBlur() {
        // const reg = /^[A-Z](?=.*[0-9].*)(?=.*[a-z].*).{5,19}$/;
        const value = this.newPassword;
        if (this.passRuleData.regularExpression) {
            const reg = this.evil('/' + this.passRuleData.regularExpression + '/');
            if (!value) {
                this.newPasswordTip = '密码不能为空！';
            }
            else if (!reg.test(value)) {
                let str = '';
                this.typeOptions.forEach(item => {
                    if (this.passRuleData[item.value]) {
                        if (str.length === 0) {
                            str += item.label;
                        }
                        else {
                            str += '、' + item.label;
                        }
                    }
                });
                const tip = `至少包含${str}，最少${this.passRuleData.passwordLength}位，最高32位`;
                this.newPasswordTip = tip;
            }
            else {
                this.newPasswordTip = '';
            }
        }
        else {
            const reg = /^[A-Z](?=.*[0-9].*)(?=.*[a-z].*).{5,19}$/;
            if (!value) {
                this.newPasswordTip = '密码不能为空！';
            }
            else if (!reg.test(value)) {
                const tip = '请以大写字母开头,6~20位';
                this.newPasswordTip = tip;
            }
            else if (value.length > 32) {
                this.newPasswordTip = '密码长度不能超过32位';
            }
            else {
                this.newPasswordTip = '';
            }
        }
        this.hasError = !!this.newPasswordTip || !!this.reconfirmTip;
    }
    /**
     * 再次确认密码失教时间
     */
    reconfirmBlur() {
        if (this.repeatNewPassword !== this.newPassword) {
            this.reconfirmTip = '两次输入密码不一致';
            this.hasError = this.hasError || true;
        }
        else {
            this.reconfirmTip = '';
        }
        this.hasError = !!this.newPasswordTip;
    }
    /**
     * 初始化
     */
    init() {
        this.password = '';
        this.newPassword = '';
        this.repeatNewPassword = '';
        this.reconfirmTip = '';
    }
    async mounted() {
        window.document.title = '修改密码';
        this.init();
    }
};
ChangePassword = __decorate([
    Component({
        name: 'ChangePassword',
        components: {
            H3Icon,
            H3Button,
            H3Input,
            CommonInput,
        },
    })
], ChangePassword);
export default ChangePassword;
